/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input {
  transition: border-color .11s ease, box-shadow .21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}

.input[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* LAYOUT
*/
.button {
  border-radius: 4px;
  padding: .85em 3em;
  font-size: 1.125rem;
  margin: 0;
  background-color: #6b4f43;
  color: #f6f2ef;
  text-align: center;
  transition: background-color .15s ease, box-shadow .15s ease;
  position: relative;
  box-shadow: none;
  /* &[disabled] {
		color: #999999;
		background-color: #ebebeb;
		cursor: not-allowed;
	}

	&.-icon {
		&::before {
			vertical-align: middle;
			margin-right: .75em;
			display: inline-block;
		}
	}

	&.-primary {
		background-color: $button-primary-bg;
		box-shadow: 0 0 0 0 $button-primary-bg;
		color: $button-primary-fg;

		&:not([disabled]) {
			&:hover,
			&:focus,
			&:active {
				box-shadow: 0 5px 20px $button-primary-bg;
			}
		}
	} */
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

.button:nth-last-child(n+2), .button:nth-last-child(n+2) ~ .button {
  margin-bottom: .5em;
}

.button:not(:first-child):not(.-block) {
  margin-left: .5em;
}

.input {
  min-height: 52px;
  padding: .3em 1em;
}

.input.-main {
  min-height: 64px;
  font-size: 1.15em;
}

.form {
  padding: 0;
  margin: 0 auto 2em;
  display: grid;
  grid-gap: 2rem;
  max-width: 80rem;
  /* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}

@media screen and (min-width: 45rem) {
  .form {
    grid-template-columns: auto auto;
  }
}

.form__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 40rem) {
  .form__section.-full {
    grid-column: span 2;
  }
}

.form__section.-to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}
