
.button {
	border-radius: $button-radius;
	padding: .85em 3em;
	font-size: rem(18px);
	margin: 0;
	background-color: $button-bg;
	color: $button-fg;
	text-align: center;
	transition: background-color .15s ease, box-shadow .15s ease;
	position: relative;
	box-shadow: none;

	&:not([disabled]) {
		&:hover,
		&:focus,
		&:active {
			z-index: 3;
			outline: 0;
			//box-shadow: 0 5px 20px $button-bg;
		}
	}

	@include quantity-query(min 2) {
		margin-bottom: .5em;
	}

	&:not(:first-child):not(.-block) {
		margin-left: .5em;
	}

	/* &[disabled] {
		color: #999999;
		background-color: #ebebeb;
		cursor: not-allowed;
	}

	&.-icon {
		&::before {
			vertical-align: middle;
			margin-right: .75em;
			display: inline-block;
		}
	}

	&.-primary {
		background-color: $button-primary-bg;
		box-shadow: 0 0 0 0 $button-primary-bg;
		color: $button-primary-fg;

		&:not([disabled]) {
			&:hover,
			&:focus,
			&:active {
				box-shadow: 0 5px 20px $button-primary-bg;
			}
		}
	} */
}
